import {json, MetaFunction} from "@remix-run/node";
import Hero from '~/routes/_index/Hero';
import {VStack} from '@styled-system/jsx';
import ActivePromotions from '~/routes/_index/ActivePromotions';
import JoinVipFooter from '~/components/layout/JoinVipFooter';
import BuildingDreamsTogether from '~/routes/_index/BuildingDreamsTogether';
import {useLoaderData} from '@remix-run/react';
import {differenceInDays} from 'date-fns';
import {startCase} from 'lodash-es';
import {GiveawayStatus} from '@prisma/client';
import {db} from 'shared/utils/db.server';
import MerchandiseForSale from '~/routes/_index/MerchandiseForSale';

export const meta: MetaFunction = () => {
  return [
    {title: "Fanathem"},
  ];
};

export const loader = async () => {
  const giveaways = await db.giveaway.findMany({
    select: {
      id: true,
      imageUrl: true,
      title: true,
      endDate: true,
      categories: true,
    },
    where: {
      imageUrl: {
        not: null
      },
      details: {
        status: GiveawayStatus.ACTIVE,
      },
      visibility: 'VISIBLE',
    },
    orderBy: {endDate: 'asc'},
    take: 3,
  });

  const products = await db.productSummaryDetails.findMany({
    where: {
      productId: {
        in: [
          '7a21d6a4-cedf-4d8c-bfb4-3f39fcd0fc91',
          'b2ba4d53-377e-44b8-ac01-b2c6d0188388',
          '1fc6e3fa-dede-4edb-a45f-5b23baae9541',
        ]
      }
    },
    select: {
      productId: true,
      title: true,
      imageUrl: true,
    }
  });

  return json({
    promotions: giveaways.map(g => ({
      id: g.id,
      imageSrc: g.imageUrl as string,
      title: g.title,
      endingSoon: differenceInDays(g.endDate, new Date()) <= 3,
      categories: g.categories.map(startCase),
    })),
    products,
  })
}

export default function Index() {
  const {promotions, products} = useLoaderData<typeof loader>();

  return (
    <VStack gap={12}>
      <Hero/>
      <MerchandiseForSale products={products}/>
      <ActivePromotions promotions={promotions}/>
      <BuildingDreamsTogether/>
      <JoinVipFooter/>
    </VStack>
  );
}
