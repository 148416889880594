import HeroStat from '~/routes/_index/HeroStat';
import {Divider, Stack} from '@styled-system/jsx';

const HeroStats = () => {
  return <Stack>
    <HeroStat number="$1,491,100" label="Paid to Creators"/>
    <Divider/>
    <HeroStat number="36.3M" label="Views"/>
    <Divider/>
    <HeroStat number="350" label="Videos Supported"/>
    <Divider/>
    <HeroStat number="61,224" label="Products Sold"/>
    <Divider/>
    <HeroStat number="51" label="Creators & Partners"/>
  </Stack>
}

export default HeroStats;
